import { CalendarIcon, FileTextIcon, HomeIcon, MagnifyingGlassIcon, StarIcon } from "@radix-ui/react-icons";
import { Link } from "@remix-run/react";
import { Badge, Box, Flex } from "sparta";
import { useDarkMode } from "sparta";
import g2xChristmasDarkMode from "~/assets/g2xChristmasDarkMode.png";
import g2xChristmasLightMode from "~/assets/g2xChristmasLightMode.png";
import {
  CompaniesIcon,
  DefenseIcon,
  FedCivIcon,
  G2XLogo,
  GovAIQ,
  HealthIcon,
  OpportunitiesIcon,
  PeopleIcon,
  Pipeline,
} from "~/icons";
import { useRootLoaderData } from "~/root";
import { CollapsibleSection } from "./CollapsibleSection";
import { MenuButton } from "./MenuButton";
import { ResponsiveSideNavContainer } from "./ResponsiveSideNavContainer";

// Beta Badge Component
const BetaBadge = ({ active = false }: { active?: boolean }) => (
  <Badge
    style={{
      fontSize: "9px",
      padding: "2px 4px",
    }}
    ml="1"
    radius="large"
    size="1"
    variant={active ? "solid" : "soft"} // Conditionally set variant
    className="beta-badge"
    color="iris"
  >
    BETA
  </Badge>
);

interface SideNavProps {
  children: React.ReactNode;
}

export function SideNav({ children }: SideNavProps) {
  const { canSeeOpportunities, canSeeAwards, canSeePipelines, canSeeFestiveLogo } = useRootLoaderData();
  const isDarkMode = useDarkMode();

  return (
    <Flex gap="0">
      <ResponsiveSideNavContainer>
        <Flex direction="column" width="190px" p="3" style={{ borderRight: "1px solid var(--gray-a6)" }}>
          <Box height="60px">
            <Link to="/" prefetch="intent" className="G2X-logo">
              {!canSeeFestiveLogo ? (
                <G2XLogo />
              ) : isDarkMode ? (
                <img src={g2xChristmasDarkMode} alt="G2X" width="70%" title="G2X Wishes You A Happy Holiday Season!" />
              ) : (
                <img src={g2xChristmasLightMode} alt="G2X" width="70%" title="G2X Wishes You A Happy Holiday Season!" />
              )}
            </Link>
          </Box>
          <Flex direction="column" gap="4">
            <CollapsibleSection>
              <MenuButton icon={<HomeIcon />} title="Home" to="/" />
              <MenuButton icon={<CalendarIcon />} title="Events" to="/events" />
              <MenuButton icon={<MagnifyingGlassIcon />} title="Search" to="/search_v2" />
            </CollapsibleSection>
            <CollapsibleSection title="Daily Take">
              <MenuButton icon={<HealthIcon />} title="Health" to="/health" />
              <MenuButton icon={<FedCivIcon />} title="FedCiv" to="/fedciv" />
              <MenuButton icon={<DefenseIcon />} title="Defense" to="/defense" />
            </CollapsibleSection>
            <CollapsibleSection title="Intelligence">
              <MenuButton icon={<FileTextIcon />} title="Reports" to="/reports" />
              {canSeeOpportunities ? (
                <MenuButton
                  icon={<OpportunitiesIcon />}
                  title={(active) => (
                    <>
                      Opportunities <BetaBadge active={active} />
                    </>
                  )}
                  to="/opportunities"
                />
              ) : null}
              {canSeeAwards ? (
                <CollapsibleSection
                  isOpen={true}
                  variant="simple"
                  collapsable
                  title="Awards"
                  rightHeaderIcon={<StarIcon />}
                >
                  <MenuButton
                    icon={<Flex width="10px" />}
                    title={(active) => (
                      <>
                        Contracts <BetaBadge active={active} />
                      </>
                    )}
                    to="/awards/contracts"
                  />
                </CollapsibleSection>
              ) : null}
            </CollapsibleSection>
            <CollapsibleSection title="Directory">
              <MenuButton icon={<PeopleIcon />} title="People" to="/people" />
              <MenuButton
                icon={<CompaniesIcon />}
                title="Companies"
                to="/companies"
                match={["/companies", "/companies/search"]}
              />
            </CollapsibleSection>
            <CollapsibleSection title="TOOLS">
              <MenuButton
                newTab={true}
                icon={<GovAIQ />}
                title={
                  <>
                    GovAIQ <BetaBadge />
                  </>
                }
                to="https://govaiq.com/login"
              />
              {canSeePipelines && (
                <MenuButton
                  icon={<Pipeline />}
                  title={(active) => (
                    <>
                      Pipelines <BetaBadge active={active} />
                    </>
                  )}
                  to="/pipelines"
                />
              )}
            </CollapsibleSection>
          </Flex>
        </Flex>
      </ResponsiveSideNavContainer>
      {children}
    </Flex>
  );
}
