import { useLocation, useNavigate } from "@remix-run/react";
import { history } from "instantsearch.js/es/lib/routers";
import type { PropsWithChildren } from "react";
import { InstantSearch } from "react-instantsearch";
import { searchClient } from "~/sdk/qs3/typesenseClient";
import { handleSearchStateChange } from "./searchUtils";

const router = history();

export function Search({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();

  function onSearchStateChange(updatedSearchState) {
    updatedSearchState.setUiState(updatedSearchState.uiState);
    const newPath = handleSearchStateChange({
      uiState: updatedSearchState.uiState,
      currentPath: location.pathname,
      router,
    });
    if (newPath) {
      navigate(newPath);
    }
  }

  return (
    <InstantSearch searchClient={searchClient} indexName="posts" onStateChange={onSearchStateChange} routing={true}>
      {children}
    </InstantSearch>
  );
}
