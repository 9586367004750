import { ChevronRightIcon } from "@radix-ui/react-icons";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Badge, Button, Flex, HoverCard, Text, useDarkMode } from "sparta";

interface CollapsibleSectionProps {
  title?: string;
  children: React.ReactNode;
  collapsable?: boolean;
  rightHeaderIcon?: React.ReactNode;
  variant?: "simple" | "heading" | "section";
  isOpen?: boolean;
  weight?: "bold" | "medium" | "light" | "regular";
  size?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
  componentId?: string;
}

export function CollapsibleSection({
  title,
  children,
  collapsable,
  rightHeaderIcon,
  variant = "heading",
  isOpen = false,
  weight = "regular",
  size,
  componentId,
}: CollapsibleSectionProps) {
  const [open, setOpen] = useState(isOpen);

  const variantStyling = {
    heading: {
      color: "gray",
      textTransform: "uppercase",
      background: "none",
      highContrast: false,
      paddingX: "0",
    },
    simple: {
      color: undefined,
      textTransform: undefined,
      background: undefined,
      highContrast: true,
      paddingX: "2",
    },
    section: {
      color: undefined,
      textTransform: undefined,
      background: undefined,
      highContrast: true,
      paddingX: "0",
    },
  };

  return (
    <Flex id={componentId} width="100%" direction="column" gap="0">
      <Button
        color={variantStyling[variant].color}
        variant="ghost"
        style={{
          width: "100%",
          justifyContent: "space-between",
          gap: "var(--space-2)",
          background: variantStyling[variant].background,
          textTransform: variantStyling[variant].textTransform,
        }}
        highContrast={variantStyling[variant].highContrast}
        rightIcon={
          collapsable ? (
            <motion.div
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              animate={{
                rotate: open ? 90 : 0,
              }}
            >
              <ChevronRightIcon />
            </motion.div>
          ) : undefined
        }
        onClick={() => setOpen(!open)}
      >
        <Flex gap="2" align="center" justify="start">
          {!!rightHeaderIcon ? rightHeaderIcon : null}
          <Text size={size} weight={weight}>
            {title}
          </Text>
        </Flex>
      </Button>
      {collapsable ? (
        <AnimatePresence>
          {open ? (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: [0.14, 0.42, 0.53, 1] }}
            >
              <Flex
                direction="column"
                width="100%"
                height={open ? "100%" : "0"}
                flexGrow="0"
                overflow="hidden"
                pt="3"
                pb="0"
                px={variantStyling[variant].section}
                gap={open ? "2" : "0"}
              >
                {children}
              </Flex>
            </motion.div>
          ) : null}
        </AnimatePresence>
      ) : (
        <Flex direction="column" width="100%" height="100%" flexGrow="0" overflow="hidden" p="2" gap="2">
          {children}
        </Flex>
      )}
    </Flex>
  );
}
