import { Link, Links, Scripts } from "@remix-run/react";
import { Flex, Grid, Heading } from "sparta";
import Logo from "~/assets/logos/General-White.svg";
import WebBlock from "../layout/WebBlock";

interface IServerError {
  caught: any;
}
export default function ServerError({ caught }: IServerError) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Uh-oh!</title>
        <Links />
      </head>
      <body>
        <WebBlock color="var(--dark-blue)" id="homepage-error">
          <Flex align="center" justify="center">
            <div>
              <Flex align="center" justify="center" height="150px">
                <img src={Logo} alt="G2X DoD" height={80} />
              </Flex>
              <Heading as="h1" weight="bold" align="center">
                {caught.status !== 404 ? "We're experiencing technical difficulties :/" : "Uh-oh! Something went wrong"}
              </Heading>
              <Flex align="center" justify="center">
                <Grid columns="auto auto" gap={caught.status === 404 ? undefined : "30px"} className="link-buttons">
                  <a href="/">Go Home</a>
                  {caught.status !== 404 ? <Link to="/logout">Log out</Link> : <div />}
                </Grid>
              </Flex>
              <Flex align="center" justify="center" direction="column" height="150px">
                <Heading as="h3">
                  {caught.status === 404 ? "Sorry, this page could not be found" : caught?.statusText}
                </Heading>
                <pre>{caught?.data}</pre>
              </Flex>
            </div>
          </Flex>
        </WebBlock>
        <Scripts />
      </body>
    </html>
  );
}
